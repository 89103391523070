import React from 'react';
import { useLocation } from 'react-router-dom';
import useEvent from '../contexts/EventContext';
import { Events } from '../constants/events';
import { env } from '../config/env';

const RouteWrapper: React.FC = () => {
  const location = useLocation();
  const { registerEvent } = useEvent();

  React.useEffect(() => {
    if (env.recordEvents) {
      registerEvent({ eventType: Events.PAGE_VIEW });
      window.analytics.page(location.pathname);
    }
  }, [location.pathname]);

  return null;
};

export default RouteWrapper;
