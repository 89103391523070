import dayjs from 'dayjs';
import { DATE_FORMAT } from '../constants';
import SubscriptionType from '../types/subscription';

export const getSubscriptionData = (
  item: any,
  type: string,
  field: string = '',
) => {
  const data = item.organization_subscriptions.find(
    (subscription: SubscriptionType) => subscription.subType === type,
  );
  if (field) {
    return data && data[field]
      ? field === 'start' || field === 'end'
        ? dayjs(data[field] * 1000).format(DATE_FORMAT)
        : data[field]
      : '';
  }
  return data;
};
