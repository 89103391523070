import React, { useMemo } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Divider, Layout, Menu, Tooltip } from 'antd';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  NodeIndexOutlined,
  HeartFilled,
  PlaySquareTwoTone,
  FileDoneOutlined,
  ReadOutlined,
  PlaySquareOutlined,
  BookOutlined,
  RiseOutlined,
  DeploymentUnitOutlined,
} from '@ant-design/icons';
import IconIcDashboardGray from '@contentment-org/icons/ic-dashboard-gray';
import IconTrendUpGray from '@contentment-org/icons/ic-trend-up-gray';
import IconGroupGray from '@contentment-org/icons/ic-group-gray';
import IconIcDashboardBlue from '@contentment-org/icons/ic-dashboard-blue';
import IconTrendUpBlue from '@contentment-org/icons/ic-trend-up-blue';
import IconGroupBlue from '@contentment-org/icons/ic-group-blue';
import { useMutation } from 'urql';

import UserContext from '../contexts/UserContext';
import AuthContext from '../contexts/AuthContext';
import SideBarContext from '../contexts/SideBarContext';
import Flex from '../components/Common/Flex';
import Loader from '../components/Common/Loader';
import {
  FlexHeader,
  Logo,
  StyledDrawer,
  StyledSider,
  StyledHeader,
  FamilyDashboard,
  ActiveRoute,
  InActiveRoute,
  StyledSubMenu,
} from './NavBarLayout';
import { LOGOUT } from '../graphql/user';
import UserMenu from './UserMenu';
import SubscriptionType from '../types/subscription';
import { getSubscriptionData } from '../utils/getSubscriptionData';
import { Subscriptions } from '../constants/subscriptions';
import useEvent from '../contexts/EventContext';
import { EventResources, Events } from '../constants/events';
import isAdmin from '../utils/isAdmin';
import { AgeGroups, CurriculumTabs } from '../constants/curriculum';
import { WellbeingLibrary } from '../constants/wellbeingLibrary';
import getCurriculumCategories from '../utils/getCurriculumCategories';
import RouteWrapper from '../components/RouteWrapper';

const PrivateRoutesLayout = () => {
  const { user } = React.useContext(UserContext);
  const { auth, setAuth } = React.useContext(AuthContext);
  const { isOpen, setIsOpen } = React.useContext(SideBarContext);
  const location = useLocation();
  const [{ fetching: loading }, logout] = useMutation(LOGOUT);
  const { registerEvent } = useEvent();

  const selectedRoute = location.pathname;

  const categories = getCurriculumCategories(
    getSubscriptionData(
      user.organization,
      Subscriptions.SchoolPlatformSubscription,
      'plan',
    ) || 'all',
  );

  const isUserAdmin = useMemo(
    () => user && user.user_roles && isAdmin(user.user_roles),
    [user?.user_roles],
  );

  const handleLogout = async () => {
    await logout({
      userId: auth.id,
    });
    registerEvent({
      eventType: Events.LOGOUT,
      eventData: {
        resourceType: EventResources.USER,
        resourceID: auth.id,
      },
    });
    setAuth({ id: '', token: '', roles: [] });
    window.location.replace('/');
  };

  const familyAppSubscription: SubscriptionType = getSubscriptionData(
    user.organization,
    Subscriptions.FamilyAppSubscription,
  );

  const schoolAndFamilyAppSubscription: SubscriptionType = getSubscriptionData(
    user.organization,
    Subscriptions.SchoolAndFamilyAppSubscription,
  );

  const curriculumMenu = (
    <StyledSubMenu
      key="/curriculum"
      icon={
        <ReadOutlined
          aria-hidden="true"
          style={{
            color:
              selectedRoute.indexOf('/curriculum') !== -1
                ? '#2FC0CE'
                : 'rgba(0,0,0,.65)',
          }}
        />
      }
      title={
        <>
          {isOpen && (
            <span
              style={{
                // position: 'absolute',
                fontSize: '14px',
                color:
                  selectedRoute.indexOf('/curriculum') !== -1
                    ? '#2FC0CE'
                    : 'rgba(0,0,0,.65)',
              }}
            >
              Student Curriculum
            </span>
          )}
        </>
      }
    >
      {categories.indexOf('infants') !== -1 && (
        <>
          <Menu.Item key="infants">
            <Link to="/curriculum/infants">
              <span>{AgeGroups.INFANTS}</span>
            </Link>
          </Menu.Item>
        </>
      )}
      {categories.indexOf('age2') !== -1 && (
        <>
          <Menu.Item key="age2">
            <Link to="/curriculum/age2">
              <span>{AgeGroups.AGE2}</span>
            </Link>
          </Menu.Item>
        </>
      )}
      {categories.indexOf('preschool') !== -1 && (
        <>
          <Menu.Item key="preschool">
            <Link to="/curriculum/preschool">
              <span>{AgeGroups.PRESCHOOL}</span>
            </Link>
          </Menu.Item>
        </>
      )}
      {categories.indexOf('ageK_2') !== -1 && (
        <>
          <Menu.Item key="ageK_2">
            <Link to="/curriculum/ageK_2">
              <span>{AgeGroups.AGE_K_2}</span>
            </Link>
          </Menu.Item>
        </>
      )}
      {categories.indexOf('age3_5') !== -1 && (
        <>
          <Menu.Item key="age3_5">
            <Link to="/curriculum/age3_5">
              <span>{AgeGroups.AGE_3_5}</span>
            </Link>
          </Menu.Item>
        </>
      )}
      {categories.indexOf('age6_8') !== -1 && (
        <>
          <Menu.Item key="age6_8">
            <Link to="/curriculum/age6_8">
              <span>{AgeGroups.AGE_6_8}</span>
            </Link>
          </Menu.Item>
        </>
      )}
      {categories.indexOf('hs') !== -1 && (
        <>
          <Menu.Item key="hs">
            <Link to="/curriculum/hs">
              <span>{AgeGroups.HS}</span>
            </Link>
          </Menu.Item>
        </>
      )}
    </StyledSubMenu>
  );

  const m3Menu = (
    <StyledSubMenu
      key="/m3"
      // role="list"
      icon={
        <PlaySquareOutlined
          style={{
            color:
              selectedRoute.indexOf('/m3') !== -1
                ? '#2FC0CE'
                : 'rgba(0,0,0,.65)',
          }}
        />
      }
      title={
        <>
          {isOpen && (
            <span
              style={{
                fontSize: '14px',
                color:
                  selectedRoute.indexOf('/m3') !== -1
                    ? '#2FC0CE'
                    : 'rgba(0,0,0,.65)',
              }}
            >
              M3 Mindfulness
            </span>
          )}
        </>
      }
    >
      <Menu.Item key="pre">
        <Link to="/m3/pre-school">
          <span>{CurriculumTabs.M3_PRE_SCHOOL}</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="primary">
        <Link to="/m3/primary">
          <span>{CurriculumTabs.M3_PRIMARY}</span>
        </Link>
      </Menu.Item>
    </StyledSubMenu>
  );

  const wellbeingLibMenu = (
    <StyledSubMenu
      // role="list"
      key="/wellbeing-library"
      icon={
        <BookOutlined
          aria-hidden="true"
          style={{
            color:
              selectedRoute.indexOf('/wellbeing-library') !== -1
                ? '#2FC0CE'
                : 'rgba(0,0,0,.65)',
          }}
        />
      }
      title={
        <>
          {isOpen && (
            <span
              style={{
                // position: 'absolute',
                fontSize: '14px',
                color:
                  selectedRoute.indexOf('/wellbeing-library') !== -1
                    ? '#2FC0CE'
                    : 'rgba(0,0,0,.65)',
              }}
            >
              Wellbeing Library
            </span>
          )}
        </>
      }
    >
      <Menu.Item key="ph">
        <Link to="/wellbeing-library/ph">
          <span>{WellbeingLibrary.PH}</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="pwb">
        <Link to="/wellbeing-library/pwb">
          <span>{WellbeingLibrary.PWB}</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="cc">
        <Link to="/wellbeing-library/cc">
          <span>{WellbeingLibrary.CC}</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="eh">
        <Link to="/wellbeing-library/eh">
          <span>{WellbeingLibrary.EH}</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="rs">
        <Link to="/wellbeing-library/rs">
          <span>{WellbeingLibrary.RS}</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="ee">
        <Link to="/wellbeing-library/ee">
          <span>{WellbeingLibrary.EE}</span>
        </Link>
      </Menu.Item>
    </StyledSubMenu>
  );

  const renderSideMenu = (shouldCollapse = true) => {
    const isCohortDetailsAvailable =
      user?.isOrganizationCohortModeEnabled &&
      (user?.assignedOrganizationCohortId ||
        user?.isOrganizationCohortHistoryAvailable);
    return (
      <Menu
        selectedKeys={[selectedRoute]}
        mode="inline"
        onClick={() => {
          if (shouldCollapse === false) {
            setIsOpen(!isOpen);
          }
        }}
      >
        {isUserAdmin && (
          <>
            <Divider orientation="left" style={{ fontSize: 12 }}>
              ADMIN
            </Divider>
            {isCohortDetailsAvailable && (
              <Menu.Item key="/cohort-details" title="Cohort Details">
                <Link to="/cohort-details">
                  {selectedRoute === `/cohort-details` ? (
                    <ActiveRoute>
                      <FileDoneOutlined />
                    </ActiveRoute>
                  ) : (
                    <InActiveRoute>
                      <FileDoneOutlined />
                    </InActiveRoute>
                  )}
                  {isOpen && (
                    <span
                      style={{
                        position: 'absolute',
                        marginLeft: '4%',
                        fontSize: '14px',
                        color:
                          selectedRoute === `/cohort-details`
                            ? '#2FC0CE'
                            : 'rgba(0,0,0,.65)',
                      }}
                    >
                      Cohort Details
                    </span>
                  )}
                </Link>
              </Menu.Item>
            )}
            <Menu.Item key="/dashboard" title="Dashboard">
              <Link to="/dashboard">
                {selectedRoute === '/dashboard' ? (
                  <span>
                    <IconIcDashboardBlue
                      style={{
                        width: '18px',
                        height: 'auto',
                        marginTop: '7px',
                        marginRight: '7px',
                        cursor: 'pointer',
                      }}
                    />
                  </span>
                ) : (
                  <span>
                    <IconIcDashboardGray
                      style={{
                        width: '16px',
                        height: 'auto',
                        marginTop: '7px',
                        marginRight: '7px',
                        cursor: 'pointer',
                      }}
                    />
                  </span>
                )}
                {isOpen && (
                  <span
                    style={{
                      position: 'absolute',
                      fontSize: '14px',
                      color:
                        selectedRoute === '/dashboard'
                          ? '#2FC0CE'
                          : 'rgba(0,0,0,.65)',
                    }}
                  >
                    Dashboard
                  </span>
                )}
              </Link>
            </Menu.Item>
            <Menu.Item key="/surveys" title="Surveys">
              <Link to="/surveys">
                {selectedRoute === '/surveys' ? (
                  <span>
                    <IconTrendUpBlue
                      style={{
                        width: '18px',
                        height: 'auto',
                        marginTop: '7px',
                        marginRight: '7px',
                        cursor: 'pointer',
                      }}
                    />
                  </span>
                ) : (
                  <span>
                    <IconTrendUpGray
                      style={{
                        width: '16px',
                        height: 'auto',
                        marginTop: '7px',
                        marginRight: '7px',
                        cursor: 'pointer',
                      }}
                    />
                  </span>
                )}
                {isOpen && (
                  <span
                    style={{
                      position: 'absolute',
                      fontSize: '14px',
                      color:
                        selectedRoute === '/surveys'
                          ? '#2FC0CE'
                          : 'rgba(0,0,0,.65)',
                    }}
                  >
                    Surveys
                  </span>
                )}
              </Link>
            </Menu.Item>
            <Menu.Item key="/members" title="Members">
              <Link to="/members">
                {selectedRoute === '/members' ? (
                  <span>
                    <IconGroupBlue
                      style={{
                        width: '18px',
                        height: 'auto',
                        marginTop: '7px',
                        marginRight: '7px',
                        cursor: 'pointer',
                      }}
                    />
                  </span>
                ) : (
                  <span>
                    <IconGroupGray
                      style={{
                        width: '16px',
                        height: 'auto',
                        marginTop: '7px',
                        marginRight: '7px',
                        cursor: 'pointer',
                      }}
                    />
                  </span>
                )}
                {isOpen && (
                  <span
                    style={{
                      position: 'absolute',
                      fontSize: '14px',
                      color:
                        selectedRoute === '/members'
                          ? '#2FC0CE'
                          : 'rgba(0,0,0,.65)',
                    }}
                  >
                    Members & Tags
                  </span>
                )}
              </Link>
            </Menu.Item>
            <Menu.Item key="/implementation" title="Implementation Guide">
              <Link to="/implementation">
                {selectedRoute === '/implementation' ? (
                  <PlaySquareTwoTone twoToneColor="rgb(47, 192, 206)" />
                ) : (
                  <PlaySquareTwoTone twoToneColor="#818D96" />
                )}
                {isOpen ? (
                  <span
                    style={{
                      position: 'absolute',
                      fontSize: '14px',
                      color:
                        selectedRoute === '/implementation'
                          ? '#2FC0CE'
                          : 'rgba(0,0,0,.65)',
                    }}
                  >
                    Implementation Guide
                  </span>
                ) : (
                  <span>Implementation Guide</span>
                )}
              </Link>
            </Menu.Item>
            <Menu.Item key="/roadmap" title="School Rollout Roadmap">
              <Link to="/roadmap">
                {selectedRoute === '/roadmap' ? (
                  <NodeIndexOutlined style={{ color: '#rgb(47, 192, 206)' }} />
                ) : (
                  <NodeIndexOutlined style={{ color: '#818D96' }} />
                )}
                {isOpen ? (
                  <span
                    style={{
                      position: 'absolute',
                      fontSize: '14px',
                      color:
                        selectedRoute === '/roadmap'
                          ? '#2FC0CE'
                          : 'rgba(0,0,0,.65)',
                    }}
                  >
                    Rollout Roadmap
                  </span>
                ) : (
                  <span>Rollout Roadmap</span>
                )}
              </Link>
            </Menu.Item>
          </>
        )}
        <Divider orientation="left" style={{ fontSize: 12 }}>
          TEACHER
        </Divider>
        {user?.isCohortTrainingMember && (
          <Menu.Item key="/wellness-team" title="Wellness Team">
            <Link to="/wellness-team">
              {selectedRoute === `/wellness-team` ? (
                <ActiveRoute>
                  <FileDoneOutlined />
                </ActiveRoute>
              ) : (
                <InActiveRoute>
                  <FileDoneOutlined />
                </InActiveRoute>
              )}
              {isOpen && (
                <span
                  style={{
                    position: 'absolute',
                    marginLeft: '4%',
                    fontSize: '14px',
                    color:
                      selectedRoute === `/wellness-team`
                        ? '#2FC0CE'
                        : 'rgba(0,0,0,.65)',
                  }}
                >
                  Wellness Team{' '}
                  <i
                    style={{
                      fontSize: '10px',
                      color: 'rgba(0,0,0,.45)',
                    }}
                  >
                    Level 2 Certification
                  </i>
                  {/* <Badge
                    className="site-badge-count-109"
                    dot
                    style={{ backgroundColor: '#52c41a' }}
                  /> */}
                </span>
              )}
            </Link>
          </Menu.Item>
        )}
        <Menu.Item key="/" title="Adult Training">
          <Link to="/">
            {selectedRoute === '/' ? (
              <ActiveRoute>
                <DeploymentUnitOutlined />
              </ActiveRoute>
            ) : (
              <InActiveRoute>
                <DeploymentUnitOutlined />
              </InActiveRoute>
            )}
            {isOpen && (
              <span
                style={{
                  position: 'absolute',
                  marginLeft: '4%',
                  fontSize: '14px',
                  color: selectedRoute === '/' ? '#2FC0CE' : 'rgba(0,0,0,.65)',
                }}
              >
                Adult Training{' '}
                <i
                  style={{
                    fontSize: '10px',
                    color: 'rgba(0,0,0,.45)',
                  }}
                >
                  Level 1 Certification
                </i>
              </span>
            )}
          </Link>
        </Menu.Item>
        {curriculumMenu}
        <Menu.Item key="/wellbeing-survey" title="Survey">
          <Link to="/wellbeing-survey" key="survey" style={{ marginRight: 25 }}>
            {selectedRoute === '/wellbeing-survey' ? (
              <ActiveRoute>
                <RiseOutlined />
              </ActiveRoute>
            ) : (
              <InActiveRoute>
                <RiseOutlined />
              </InActiveRoute>
            )}
            {isOpen && (
              <span
                style={{
                  position: 'absolute',
                  marginLeft: '4%',
                  fontSize: '14px',
                  color:
                    selectedRoute === '/wellbeing-survey'
                      ? '#2FC0CE'
                      : 'rgba(0,0,0,.65)',
                }}
              >
                Survey
              </span>
            )}
          </Link>
        </Menu.Item>
        {(user.nationality === 'New Zealand' ||
          user.organization?.country === 'New Zealand' ||
          user.organization?.address?.country === 'New Zealand') &&
          m3Menu}
        {wellbeingLibMenu}
      </Menu>
    );
  };

  if (loading) {
    return <Loader height="100vh" size="large" />;
  }
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <StyledHeader>
        <Flex alignItems="center" justifyContent="space-between" wrap="nowrap">
          <Flex alignItems="center" wrap="nowrap" style={{ marginLeft: 30 }}>
            {isOpen ? (
              <MenuFoldOutlined
                onClick={() => {
                  setIsOpen(false);
                }}
              />
            ) : (
              <MenuUnfoldOutlined
                onClick={() => {
                  setIsOpen(true);
                }}
              />
            )}
            <Link to="/" style={{ marginLeft: '40px' }}>
              <Logo alt="logo" src="/assets/logo.svg" />
            </Link>
          </Flex>
          <FlexHeader
            alignItems="center"
            wrap="nowrap"
            justifyContent="space-between"
          >
            <UserMenu onLogout={handleLogout} />
            {familyAppSubscription || schoolAndFamilyAppSubscription ? (
              <Tooltip title="Go to Family Dashboard" placement="bottomRight">
                <FamilyDashboard
                  href="https://family.contentment.org"
                  target="_blank"
                >
                  <HeartFilled style={{ color: '#FFFFFF', fontSize: '16px' }} />
                </FamilyDashboard>
              </Tooltip>
            ) : (
              <Tooltip
                title="Sign up for Family Dashboard"
                placement="bottomRight"
              >
                <FamilyDashboard
                  href={`https://family.contentment.org/signup?id=${user.organization.id}`}
                  target="_blank"
                >
                  <HeartFilled style={{ color: '#FFFFFF', fontSize: '16px' }} />
                </FamilyDashboard>
              </Tooltip>
            )}
          </FlexHeader>
        </Flex>
      </StyledHeader>
      <Layout style={{ paddingTop: 64 }}>
        <StyledSider trigger={null} collapsible collapsed={!isOpen} width={256}>
          {renderSideMenu(true)}
        </StyledSider>
        <StyledDrawer
          placement="left"
          onClose={() => setIsOpen(false)}
          open={isOpen}
          closable
          title={
            <Link to="/">
              <img alt="logo" className="logo" src="/assets/logo.svg" />
            </Link>
          }
        >
          {renderSideMenu(false)}
        </StyledDrawer>
        <Layout>
          <Layout.Content
            style={{
              padding: '16px',
              position: 'relative',
            }}
          >
            <React.Suspense fallback={<Loader />}>
              <RouteWrapper />
              <Outlet />
            </React.Suspense>
          </Layout.Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default PrivateRoutesLayout;
