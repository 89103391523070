import styled from 'styled-components';
import { Layout, Drawer, Divider, Button, Menu } from 'antd';
import Flex from '../components/Common/Flex';
import { media } from '../utils/mediaQueries';

const { SubMenu } = Menu;

export const Logo = styled.img`
  height: 34px;
  background: rgba(255, 255, 255, 0.2);
  cursor: pointer;
`;

export const StyledSider = styled(Layout.Sider)`
  display: none;
  ${media.lg`
    display: block;
    background: #fff;
  `}
`;

export const StyledDrawer = styled(Drawer)`
  ${media.lg`
    display: none;
  `}
`;

export const FlexHeader = styled(Flex)`
  height: 64px;
  // margin-right: 11px;
`;

export const PopoverWrapper = styled.div`
  cursor: pointer;
  margin: 0;
  ${media.lg`
    margin: 0 40px;
  `};
`;

export const NameContainer = styled.span`
  margin-left: 7px;
  /* color: rgb(129, 141, 150); */
  /* font-family: 'Varela Regular'; */
  display: none;
  margin-right: 3px;
  ${media.lg`
    display: block;
  `}
`;

export const AvatarMenuContainer = styled.div`
  cursor: pointer;
  flex: 2 1 0%;
  margin-left: 15px;
  margin-right: 15px;
  ${media.lg`
    margin-left: 0;
  `}
`;

export const StyledHeader = styled(Layout.Header)`
  background: #fff;
  padding: 0;
  position: fixed;
  z-index: 100;
  width: 100%;
  border-bottom: 1px solid #f1f1f1;
`;

export const TeacherDashboard = styled(Button)`
  margin-right: 25px;
`;

export const FamilyDashboard = styled.a`
  background: #54bf97;
  padding: 0 15px;
  cursor: pointer;

  & :hover {
    box-shadow: inset 0 0 20px 0px rgb(255 255 255 / 50%);
  }
`;

export const RoleSwitcher = styled.span`
  background: #0190be;
  padding: 0 15px;
  cursor: pointer;

  & :hover {
    box-shadow: inset 0 0 20px 0px rgb(255 255 255 / 50%);
  }
`;

export const ActiveRoute = styled.span`
  color: #2fc0ce;
  font-size: 14px;
`;

export const InActiveRoute = styled.span`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  &:hover {
    color: #2fc0ce;
  }
`;

export const Separator = styled(Divider)`
  border-left: 1px solid rgba(0, 0, 0, 0.5);
`;

export const StyledSubMenu = styled(SubMenu)`
  ul.ant-menu-sub > li > a {
    color: #ffffff;
  }
  ul.ant-menu-sub > li.ant-menu-item-selected > a {
    color: #2fc0ce;
    font-weight: 700;
  }
  ul.ant-menu-sub > li:hover > a {
    color: #2fc0ce;
  }
`;

export const PlatformBannerContainer = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

export const PlatformBannerFlatLine = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 6;
  border-top: 1px solid #0190be;
  border-radius: 0;
  text-align: center;
  line-height: 0;
  pointer-events: none;
`;

export const PlatformBannerContent = styled.div`
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  height: 14px;
  padding: 2px;
  background-color: #0190be;
  color: #fff;
  font-size: 10px;
  line-height: 10px;
  font-weight: 700;

  & :before {
    content: '';
    position: absolute;
    top: 0;
    left: -8px;
    right: -8px;
    height: 100%;
    z-index: -1;
    background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE0IiB3aWR0aD0iOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNi41MDQgMTRINS4wMDdBMy4wMDQgMy4wMDQgMCAwMTIgMTFWMi4wMUEyLjAwOSAyLjAwOSAwIDAwLS4wMDYgMGgtLjk5N0g4djE0eiIgZmlsbD0iIzAxOTBiZSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+),
      url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE0IiB3aWR0aD0iOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMS40OTMgMTRIMi45OWEzLjAwNCAzLjAwNCAwIDAwMy4wMDctM1YyLjAxQTIuMDEgMi4wMSAwIDAxOC4wMDMgMEg5LS4wMDN2MTR6IiBmaWxsPSIjMDE5MGJlIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=);
    background-repeat: no-repeat;
    background-position: 0 0, 100% 0;
    background-size: 10px 14px;
  }
`;
