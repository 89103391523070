import React from 'react';

const Login = React.lazy(() => import('../pages/Login'));
const Signup = React.lazy(() => import('../pages/Signup'));
const ForgotPassword = React.lazy(() => import('../pages/ForgotPassword'));
const AdminDashboard = React.lazy(() => import('../pages/admin/Dashboard'));
const Members = React.lazy(() => import('../pages/admin/Members'));
const Training = React.lazy(() => import('../pages/teachers/Training'));
const Curriculum = React.lazy(() => import('../pages/teachers/Curriculum'));
const WbSurvey = React.lazy(() => import('../pages/teachers/WbSurvey'));
const Wb = React.lazy(() => import('../pages/teachers/WbLibrary'));
const M3 = React.lazy(() => import('../pages/teachers/M3'));
const Player = React.lazy(() => import('../pages/teachers/Player'));
// const NotFound = React.lazy(() => import('../pages/NotFound'));
const AdminSurveys = React.lazy(() => import('../pages/admin/Surveys'));
const TeacherSignup = React.lazy(() => import('../pages/TeacherSignup'));
const SchoolSignUp = React.lazy(() => import('../pages/SchoolSignup'));
const Profile = React.lazy(() => import('../pages/Profile'));
const TakeSurvey = React.lazy(() => import('../pages/teachers/TakeSurvey'));
const PersonalSurvey = React.lazy(
  () => import('../components/Common/PersonalSurvey'),
);
const Implementation = React.lazy(
  () => import('../pages/admin/Implementation'),
);
const Roadmap = React.lazy(() => import('../pages/admin/Roadmap'));
const Clever = React.lazy(() => import('../pages/Clever'));
const WellnessTeam = React.lazy(() => import('../pages/teachers/WellnessTeam'));
const CohortDetails = React.lazy(() => import('../pages/admin/CohortDetails'));
const Certificates = React.lazy(
  () => import('../components/Certificates/Certificates'),
);
import { SurveyTypes } from '../types/survey';

export interface RouteType {
  path: string;
  component: React.ReactElement;
  title: string;
}

export const publicPaths: string[] = [
  '/',
  '/signup',
  '/forgot-password',
  '/invite',
  '/school/signup',
  '/oauth/clever',
];

export const isPublicPath = (path: string): boolean => {
  return publicPaths.includes(path);
};

// Make sure new routes are added before `*` path otherwise it will not match new route path.

export const publicRoutes: RouteType[] = [
  {
    path: '/',
    component: <Login />,
    title: 'Login',
  },
  {
    path: '/signup',
    component: <Signup />,
    title: 'Sign up',
  },
  {
    path: '/forgot-password',
    component: <ForgotPassword />,
    title: 'Forgot Password',
  },
  {
    path: '/invite',
    component: <TeacherSignup />,
    title: 'Invite',
  },
  {
    path: '/school/signup',
    component: <SchoolSignUp />,
    title: 'School Sing Up',
  },
  {
    path: `/oauth/clever`,
    component: <Clever />,
    title: 'Clever Login',
  },
  {
    path: '*',
    component: <Login />,
    title: 'No match found',
  },
];

export const teacherRoutes: RouteType[] = [
  {
    path: '/',
    component: <Training />,
    title: 'Training',
  },
  {
    path: '/wellness-team',
    component: <WellnessTeam />,
    title: 'Wellness Team',
  },
  {
    path: '/player/:pillar/:lesson/:item/:index/:curriculum',
    component: <Player />,
    title: 'Player',
  },
  {
    path: '/curriculum/:plan',
    component: <Curriculum />,
    title: 'Curriculum',
  },
  {
    path: '/survey/:surveyId',
    component: <TakeSurvey surveyType={SurveyTypes.SchoolWide} />,
    title: 'TakeSurvey',
  },
  {
    path: '/wellbeing-survey',
    component: <WbSurvey />,
    title: 'Survey',
  },
  {
    path: '/personal-survey',
    component: <PersonalSurvey />,
    title: 'Personal Survey',
  },
  {
    path: '/wellbeing-library/:category',
    component: <Wb />,
    title: 'Wellbeing Library',
  },
  {
    path: '/m3/:series',
    component: <M3 />,
    title: 'Mindfulness, Meditation, & Movement',
  },
  {
    path: '/profile',
    component: <Profile />,
    title: 'Profile',
  },
  {
    path: '/certificates',
    component: <Certificates />,
    title: 'Certificates',
  },
  {
    path: '*',
    component: <Training />,
    title: 'Training',
  },
];

export const adminRoutes: RouteType[] = [
  {
    path: '/cohort-details',
    component: <CohortDetails />,
    title: 'Cohort Details',
  },
  {
    path: '/dashboard',
    component: <AdminDashboard />,
    title: 'Dashboard',
  },
  {
    path: '/members',
    component: <Members />,
    title: 'Members',
  },
  {
    path: '/surveys',
    component: <AdminSurveys />,
    title: 'Surveys',
  },
  {
    path: '/implementation',
    component: <Implementation />,
    title: 'Implementation Guide',
  },
  {
    path: '/roadmap',
    component: <Roadmap />,
    title: 'School Rollout Roadmap',
  },
];
