import { Roles } from '../constants/roles';

const isAdmin = (
  userRoles: {
    role: {
      name: string;
    };
  }[],
): boolean => {
  return Boolean(
    userRoles.find((roles: any) => roles.role.name == Roles.ORG_ADMIN),
  );
};

export default isAdmin;
