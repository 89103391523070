export const DATE_FORMAT = `MMMM DD, YYYY`;
export const DATE_INPUT_FORMAT = `MMM DD, YYYY`;
export const DB_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'MMM DD, YYYY h:mm a';
export const TIME_INPUT_FORMAT = 'HH:mm';
export const TIME_FORMAT = 'h:mm a';
export const DAY_FORMAT = 'dddd';

export enum Modes {
  Edit = 'EDIT',
  Add = 'ADD',
}

export enum COMMON_AUTH_VIEWS {
  FORGOT_PASSWORD = 'forgot_password',
  TEACHER_SIGN_UP = 'teacher_sign_up',
  SCHOOL_SIGN_UP = 'school_sign_up',
}

export const SCHOOL_SIGN_UP_SESSION_KEY = 'school_sign_up';
