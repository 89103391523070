import React, { useCallback } from 'react';
import { useClient } from 'urql';

import { CohortTraining, CohortTrainingMember } from '../types/cohorts';
import { GET_COHORT_TRAINING_MEMBERS } from '../graphql/cohort';
import UserContext from './UserContext';

export interface Cohort {
  id: string;
  name: string;
  startTime: string;
  endTime: string;
  timeZone: string;
  cohort_trainings?: CohortTraining[]; // used for the admin cohort details page
  trainingMembers?: CohortTrainingMember[]; // used for the user wellness page
}

interface CohortContextInterface {
  availableCohorts?: Cohort[];
  selectedCohortId?: string;
  setSelectedCohortId: (arg0: string) => void;
  fetchCohortTrainingMembers: () => void;
  fetchingCohorts: boolean;
  organizationCohorts?: Cohort[];
  fetchAllCohortTrainingMembers: () => void;
}

const CohortContext = React.createContext<CohortContextInterface>({
  availableCohorts: undefined,
  selectedCohortId: undefined,
  setSelectedCohortId: () => {},
  fetchCohortTrainingMembers: () => {},
  fetchingCohorts: false,
  organizationCohorts: undefined,
  fetchAllCohortTrainingMembers: () => {},
});

export const CohortContextProvider = ({ children }: any) => {
  const client = useClient();
  const { user } = React.useContext(UserContext);
  const [availableCohorts, setAvailableCohorts] = React.useState<Cohort[]>();
  const [selectedCohortId, setSelectedCohortId] = React.useState<string>();
  const [fetchingCohorts, setFetchingCohorts] = React.useState<boolean>(false);
  const [organizationCohorts, setOrganizationCohorts] =
    React.useState<Cohort[]>();

  const fetchCohortTrainingMembers = useCallback(async () => {
    setFetchingCohorts(true);
    const { data } = await client
      .query(GET_COHORT_TRAINING_MEMBERS, {
        where: { userId: { _eq: user.id } },
      })
      .toPromise();

    const cohortData: Cohort[] = data?.schools_cohort_training_members?.reduce(
      (acc: Cohort[], trainingMember: CohortTrainingMember) => {
        const cohortId = trainingMember.cohort_training.cohortId;
        const existingCohortIndex = acc.findIndex((c) => c.id === cohortId);

        if (existingCohortIndex >= 0) {
          // Cohort already exists, update its trainingMembers
          acc[existingCohortIndex].trainingMembers?.push(trainingMember);
          acc[existingCohortIndex].endTime =
            trainingMember.cohort_training.endTime;
        } else {
          // Add new Cohort
          acc.push({
            id: trainingMember.cohort_training.cohortId,
            name: trainingMember.cohort_training.cohort.name,
            startTime: trainingMember.cohort_training.startTime,
            endTime: trainingMember.cohort_training.endTime,
            trainingMembers: [trainingMember],
            timeZone: trainingMember.cohort_training.cohort.timeZone,
          });
          setSelectedCohortId(trainingMember.cohort_training.cohortId); // update selected cohort id to the latest one
        }

        return acc;
      },
      [],
    );
    setAvailableCohorts(cohortData);
    setFetchingCohorts(false);
  }, [client, user.id]);

  React.useEffect(() => {
    user?.id && user?.isCohortTrainingMember && fetchCohortTrainingMembers();
  }, [user?.id, user?.isCohortTrainingMember]);

  const fetchAllCohortTrainingMembers = useCallback(async () => {
    setFetchingCohorts(true);
    const { data } = await client
      .query(GET_COHORT_TRAINING_MEMBERS)
      .toPromise();

    if (data && data.schools_cohort_training_members) {
      const cohortsData: Cohort[] = data.schools_cohort_training_members.reduce(
        (acc: Cohort[], trainingMember: CohortTrainingMember) => {
          const cohortTraining = trainingMember.cohort_training;
          const cohortId = cohortTraining.cohortId;
          const trainingId = cohortTraining.id;

          let updatedCohorts = acc;
          const existingCohortIndex = acc.findIndex((c) => c.id === cohortId);

          if (existingCohortIndex >= 0) {
            let updatedCohort = { ...updatedCohorts[existingCohortIndex] };
            updatedCohort.trainingMembers = [
              ...(updatedCohort.trainingMembers || []),
              trainingMember,
            ];

            // Initialize cohort_trainings if undefined
            updatedCohort.cohort_trainings =
              updatedCohort.cohort_trainings ?? [];

            const existingTrainingIndex =
              updatedCohort.cohort_trainings.findIndex(
                (t) => t.id === trainingId,
              );

            if (existingTrainingIndex >= 0) {
              let updatedTraining = {
                ...updatedCohort.cohort_trainings[existingTrainingIndex],
              };
              updatedTraining.cohort_training_members = [
                ...(updatedTraining.cohort_training_members || []),
                trainingMember,
              ];

              updatedCohort.cohort_trainings[existingTrainingIndex] =
                updatedTraining;
            } else {
              const newTraining = {
                ...cohortTraining,
                cohort_training_members: [trainingMember],
              };
              updatedCohort.cohort_trainings.push(newTraining);
            }

            // Update endTime if the new training member's endTime is later
            if (
              !updatedCohort.endTime ||
              cohortTraining.endTime > updatedCohort.endTime
            ) {
              updatedCohort.endTime = cohortTraining.endTime;
            }

            updatedCohorts = [
              ...updatedCohorts.slice(0, existingCohortIndex),
              updatedCohort,
              ...updatedCohorts.slice(existingCohortIndex + 1),
            ];
          } else {
            const newCohort = {
              id: cohortId,
              name: cohortTraining.cohort.name,
              startTime: cohortTraining.startTime,
              endTime: cohortTraining.endTime,
              timeZone: cohortTraining.cohort.timeZone,
              trainingMembers: [trainingMember],
              cohort_trainings: [
                {
                  ...cohortTraining,
                  cohort_training_members: [trainingMember],
                },
              ],
            };
            updatedCohorts = [...updatedCohorts, newCohort];
          }

          return updatedCohorts;
        },
        [],
      );
      // if the current cohort doesn't have any members add it explicitly
      if (
        user?.organization?.cohort?.id &&
        !cohortsData.some(
          (cohortData: Cohort) => user.organization.cohort.id === cohortData.id,
        )
      ) {
        cohortsData.push(user.organization.cohort);
      }
      setOrganizationCohorts(cohortsData);
    }
    setFetchingCohorts(false);
  }, [client, user?.organization?.cohort]);

  React.useEffect(() => {
    user?.isOrganizationCohortModeEnabled &&
      (user?.isOrganizationCohortHistoryAvailable ||
        user?.assignedOrganizationCohortId) &&
      fetchAllCohortTrainingMembers();
  }, [
    user?.isOrganizationCohortModeEnabled,
    user?.isOrganizationCohortHistoryAvailable,
    user?.assignedOrganizationCohortId,
  ]);

  return (
    <CohortContext.Provider
      value={{
        availableCohorts,
        selectedCohortId,
        setSelectedCohortId,
        fetchCohortTrainingMembers,
        fetchingCohorts,
        organizationCohorts,
        fetchAllCohortTrainingMembers,
      }}
    >
      {children}
    </CohortContext.Provider>
  );
};

export default CohortContext;
