import React from 'react';

import Loader from '../components/Common/Loader';
import {
  getRefreshToken,
  setRefreshToken,
  expiresIn,
} from '../utils/authToken';
import { isPublicPath } from '../utils/routes';

interface AuthContextInterface {
  auth?: any;
  setAuth: (arg0?: any) => void;
}

const AuthContext = React.createContext<AuthContextInterface>({
  auth: null,
  setAuth: () => {},
});

// use window.location instead of react-router-dom hooks
// to reduce re-render on route change

export const AuthContextProvider = ({ children }: any) => {
  const [auth, setAuth] = React.useState({
    id: '',
    token: '',
    roles: ['user'],
  });
  const [loading, setLoading] = React.useState(true);
  let intervalRef: any = null;

  React.useEffect(() => {
    let isCancelled = false;

    const getToken = async () => {
      try {
        const tokenData = await getRefreshToken();
        if (!isCancelled) {
          setAuthData(tokenData);
          setLoading(false);
        }
      } catch (err) {
        setAuthData({ id: '', token: '', roles: ['user'] });
        setLoading(false);
      }
    };

    getToken();

    return function cleanup() {
      isCancelled = true;
      if (intervalRef) {
        clearInterval(intervalRef);
      }
    };
  }, []);

  const setAuthData = async (data: {
    id: string;
    token: string;
    roles: string[];
  }) => {
    if (!data.id || !data.token) {
      // @ts-ignore
      // if (window.analytics) {
      //   // @ts-ignore
      //   window.analytics.identify(Date.now());
      // }
      if (!sessionStorage.getItem('cfc_session')) {
        sessionStorage.setItem(
          'cfc_session',
          `session_${Date.now().toString()}`,
        );
      }

      // set only if its not null / empty
      if (auth.id || auth.token) {
        setAuth({ id: '', token: '', roles: [] });
      }
      setRefreshToken({ id: '', token: '', roles: [] });

      // clear interval to refetch access token
      if (intervalRef) {
        clearInterval(intervalRef);
      }

      if (!isPublicPath(window.location.pathname)) {
        window.history.replaceState(null, '', '/');
      }

      return;
    }

    // @ts-ignore
    // if (window.anlytics) {
    //   // @ts-ignore
    //   window.analytics.identify(data.id, {
    //     email: data.email,
    //     name: `${data.firstName} ${data.lastName}`,
    //   });
    // }

    setAuth(data);
    setRefreshToken(data);

    // set new auth token
    intervalRef = setInterval(async () => {
      const newTokenData = await getRefreshToken(true);
      setAuth(newTokenData);
    }, expiresIn);
  };

  if (loading) {
    return <Loader height="100vh" size="large" />;
  }

  return (
    <AuthContext.Provider value={{ auth, setAuth: setAuthData }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
