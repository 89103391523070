export default function getCurriculumCategories(plan: string) {
  let categories: string[] = [];
  switch (plan) {
    case 'prek_elem':
      categories = [
        ...categories,
        'preschool',
        'age2',
        'infants',
        'ageK_2',
        'age3_5',
        'age6_8',
      ];
      break;
    case 'elem_hs':
      categories = [...categories, 'ageK_2', 'age3_5', 'age6_8', 'hs'];
      break;
    case 'prek':
      categories = [...categories, 'preschool', 'age2', 'infants'];
      break;
    case 'elementary':
      categories = [...categories, 'ageK_2', 'age3_5', 'age6_8'];
      break;
    case 'hs':
      categories = [...categories, 'hs'];
      break;
    case 'all':
      categories = [
        ...categories,
        'preschool',
        'age2',
        'infants',
        'ageK_2',
        'age3_5',
        'age6_8',
        'hs',
      ];
      break;
    default:
      categories = [
        ...categories,
        'preschool',
        'age2',
        'infants',
        'ageK_2',
        'age3_5',
        'age6_8',
        'hs',
      ];
  }
  return categories;
}
