import {
  createClient as createUrqlClient,
  dedupExchange,
  fetchExchange,
  cacheExchange,
  errorExchange,
  subscriptionExchange,
} from 'urql';
import * as Sentry from '@sentry/react';
import { createClient as createWSClient, SubscribePayload } from 'graphql-ws';
import { env } from './env';

// generate headers
const generateHeaders = (auth: any) => ({
  authorization: `Bearer ${auth.token}`,
  'x-platform': 'SCHOOL_PLATFORM',
  'x-contentment-url': window.location.origin,
});

// create websocket client
const getWsClient = (auth: any) => {
  return createWSClient({
    url: <string>env.subscriptionsURL,
    connectionParams: {
      headers: generateHeaders(auth),
    },
    lazy: true,
  });
};

export const authRoutesGqlClient = (auth: any) => {
  const wsClient = getWsClient(auth);

  return createUrqlClient({
    url: <string>env.graphqlURL,
    requestPolicy: 'cache-and-network',
    exchanges: [
      dedupExchange,
      cacheExchange,
      errorExchange({
        onError(error) {
          Sentry.captureException(error);
        },
      }),
      subscriptionExchange({
        forwardSubscription(operation) {
          return {
            subscribe: (sink) => {
              const dispose = wsClient.subscribe(
                operation as SubscribePayload,
                sink,
              );
              return {
                unsubscribe: dispose,
              };
            },
          };
        },
      }),
      fetchExchange,
    ],
    fetchOptions: () => {
      return {
        headers: generateHeaders(auth),
        credentials: 'include',
      };
    },
  });
};

export const openRoutesGqlClient = createUrqlClient({
  url: `${<string>env.apiURL || `https://gql-api.contentment.org`}/graphql`,
  fetchOptions: () => {
    return {
      headers: {
        'x-platform': 'SCHOOL_PLATFORM',
        'x-contentment-url': window.location.origin,
      },
      credentials: 'include',
    };
  },
});
