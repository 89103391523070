import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { isIE, isMobile } from 'react-device-detect';
import { useNavigatorStatus } from 'react-navigator-status';
import { Alert, Button } from 'antd';
import styled from 'styled-components';

import { AuthContextProvider } from './contexts/AuthContext';
import Flex from './components/Common/Flex';
import Router from './Router';
import { EventContextProvider } from './contexts/EventContext';

declare global {
  interface Window {
    analytics: any;
  }
}

const AlertWrapper = styled(Flex)`
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
`;

const App: React.FC = () => {
  const [showAlert, setShowAlert] = React.useState(false);
  const isOnline = useNavigatorStatus();

  React.useEffect(() => {
    let isMounted = true;

    if (isOnline && showAlert && isMounted) {
      setShowAlert(true);

      setTimeout(() => {
        if (isMounted) {
          setShowAlert(false);
        }
      }, 4000);
    }

    if (!isOnline && isMounted) {
      setShowAlert(true);
    }

    return () => {
      isMounted = false;
    };
  }, [isOnline]);

  return (
    <>
      {isIE && (
        <AlertWrapper
          justifyContent="center"
          style={{
            position: 'fixed',
            top: 0,
            zIndex: 999,
            width: '100%',
          }}
        >
          <Alert
            message="Your browser is currently not supported"
            description=""
            type="error"
            closable
            action={
              <Button
                size="small"
                type="link"
                href="https://www.google.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                UPDATE
              </Button>
            }
          />
        </AlertWrapper>
      )}

      {isMobile && (
        <AlertWrapper justifyContent="center">
          <Alert
            style={{ textAlign: 'center' }}
            message="Please visit from a computer (laptop or desktop) for best user experience"
            description=""
            type="warning"
            closable
          />
        </AlertWrapper>
      )}

      {showAlert && (
        <AlertWrapper justifyContent="center">
          {isOnline ? (
            <Alert
              style={{ textAlign: 'center' }}
              message="You are online"
              description=""
              type="success"
              closable
            />
          ) : (
            <Alert
              style={{ textAlign: 'center' }}
              message="You are offline please check your connection"
              description=""
              type="error"
              closable
            />
          )}
        </AlertWrapper>
      )}

      <AuthContextProvider>
        <EventContextProvider>
          <Router />
        </EventContextProvider>
      </AuthContextProvider>
    </>
  );
};

export default App;
