export const Events = {
  PAGE_VIEW: `PAGE_VIEW`,
  LOGIN_ATTEMPT: `LOGIN_ATTEMPT`,
  LOGIN_OTP_SENT: `LOGIN_OTP_SENT`,
  LOGIN_OTP_VERIFIED: `LOGIN_OTP_VERIFIED`,
  LOGIN_SUCCESSFUL: `LOGIN_SUCCESSFUL`,
  LOGIN_FAILED: `LOGIN_FAILED`,
  LOGOUT: `LOGOUT`,
  MEMBER_ROLE_CHANGED: `MEMBER_ROLE_CHANGED`,
  SCHEDULE_SURVEY: `SCHEDULE_SURVEY`,
  SURVEY_END_DATE_CHANGE: `SURVEY_END_DATE_CHANGE`,
  SURVEY_CHANGE_SCHEDULE: `SURVEY_CHANGE_SCHEDULE`,
  SURVEY_UPDATE_ARCHIVE_STATE: `SURVEY_UPDATE_ARCHIVE_STATE`,
  REOPEN_SURVEY: `REOPEN_SURVEY`,
  REMOVE_SURVEY: `REMOVE_SURVEY`,
  START_SURVEY: `START_SURVEY`,
  END_SURVEY: `END_SURVEY`,
  REMIND_SURVEY_UNRESPONSIVE_MEMBERS: `REMIND_SURVEY_UNRESPONSIVE_MEMBERS`,
  SURVEY_ADD_REMOVE_PARTICIPANTS: `SURVEY_ADD_REMOVE_PARTICIPANTS`,
  REMOVE_MEMBER_FROM_SURVEY: `REMOVE_MEMBER_FROM_SURVEY`,
  SAVE_TEACHER_SURVEY: `SAVE_TEACHER_SURVEY`,
  SAVE_PERSONAL_SURVEY: `SAVE_PERSONAL_SURVEY`,
  SAVE_FIDELITY_SURVEY: `SAVE_FIDELITY_SURVEY`,
  SAVE_AUTOMATED_SURVEY_CONFIG: `SAVE_AUTOMATED_SURVEY_CONFIG`,
  UNLOCK_PILLARS_FOR_GROUP: `UNLOCK_PILLARS_FOR_GROUP`,
  CHANGE_EMAIL: `CHANGE_EMAIL`,
  CHANGE_MEMBER_NAME: `CHANGE_MEMBER_NAME`,
  CHANGE_PASSWORD: `CHANGE_PASSWORD`,
  FORGOT_PASSWORD: `FORGOT_PASSWORD`,
  TEACHER_SIGNUP: `TEACHER_SIGNUP`,
  UPLOAD_PROFILE_PICTURE: `UPLOAD_PROFILE_PICTURE`,
  INVITE_MEMBER: `INVITE_MEMBER`,
  INVITE_FOUR_PILLAR_ADMIN: `INVITE_FOUR_PILLAR_ADMIN`,
  UPDATE_MULTI_FACTOR_AUTH: `UPDATE_MULTI_FACTOR_AUTH`,
  CHANGE_GROUP: `CHANGE_GROUP`,
  VERIFY_MEMBER_INVITE: `VERIFY_MEMBER_INVITE`,
  REMIND_UN_RESPONSIVE_MEMBERS: `REMIND_UN_RESPONSIVE_MEMBERS`,
  DEACTIVATE_MEMBER: `DEACTIVATE_MEMBER`,
  RESEND_INVITE: `RESEND_INVITE`,
  ORGANIZATION_SIGN_UP: `ORGANIZATION_SIGN_UP`,
  ORG_PAYMENT: `ORG_PAYMENT`,
  UPDATE_LESSON: `UPDATE_LESSON`,
  INTRO_VIDEO_STATUS: `INTRO_VIDEO_STATUS`,
  UNLOCK_CURRICULUM: `UNLOCK_CURRICULUM`,
  UPDATE_USER_AGREEMENT: `UPDATE_USER_AGREEMENT`,
  GET_COHORT_TRAINING_LINK: `GET_COHORT_TRAINING_LINK`,
  JOIN_COHORT: `JOIN_COHORT`,
  LEAVE_COHORT: `LEAVE_COHORT`,
  ADD_COHORT_TRAINING_MEMBER: `ADD_COHORT_TRAINING_MEMBER`,
  REMOVE_COHORT_TRAINING_MEMBER: `REMOVE_COHORT_TRAINING_MEMBER`,
  ADD_COHORT_TRAINING_FEEDBACK: `ADD_COHORT_TRAINING_FEEDBACK`,
  CLICK_COHORT_TRAINING_ADD_TO_CALENDAR_LINK: `CLICK_COHORT_TRAINING_ADD_TO_CALENDAR_LINK`,
  CERTIFICATE_NAME_VERIFIED: `CERTIFICATE_NAME_VERIFIED`,
  CERTIFICATE_DOWNLOADED: `CERTIFICATE_DOWNLOADED`,
  MEMBER_PILLAR_UNLOCKED: `MEMBER_PILLAR_UNLOCKED`,
  UPDATE_TAGS: `UPDATE_TAGS`,
};

export const EventResources = {
  USER: `USER`,
  GROUP: `GROUP`,
  ORGANIZATION: `ORGANIZATION`,
  SURVEY: `SURVEY`,
  SURVEY_GROUP: `SURVEY_GROUP`,
  SURVEY_MEMBER: `SURVEY_MEMBER`,
  COHORT_TRAINING_MEMBER: `COHORT_TRAINING_MEMBER`,
  CERTIFICATE: `CERTIFICATE`,
};
