export enum SurveyTypes {
  SchoolWide = 'SchoolWide',
  Personal = 'Personal',
  Fidelity = 'Fidelity',
}

export type SchoolSurveyDataType = {
  name: string;
  reverse: boolean;
  title: Record<string, string>;
  answer?: string | null;
};

export enum FidelitySurveyResponseTypes {
  PercentageMetricSlider,
  HoursMetricSlider,
  MetricBubble,
  Text,
}

export type FidelityResponse = {
  id: string;
  score: number | null;
  na: boolean;
  text: string | null;
};

export type FidelityResponseObject = {
  block: number;
  construct: string;
  subject: string;
  responses: FidelityResponse[];
  questionType: FidelitySurveyResponseTypes;
  skipped: boolean;
};

export type FidelityQuestions = {
  block: number;
  construct: string;
  question: string;
  subject: string;
  responseType: FidelitySurveyResponseTypes;
  responses: {
    id: string;
    label: string;
  }[];
};

export interface AutomatedSurveyConfig {
  startDate: string;
  endDate: string;
  notificationDates: string[];
}

export interface AutomatedSurveyConfigInput {
  startDate: string;
  endDate: string;
  notificationDate: string;
}

export interface SchoolSurveyMembers {
  id: string;
  status: string;
  user: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    member: {
      id: string;
    };
  };
}
