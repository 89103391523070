import React from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import { Link, Outlet } from 'react-router-dom';

const { Footer, Content } = Layout;
import { Logo } from './NavBarLayout';
import Flex from '../components/Common/Flex';
import Loader from '../components/Common/Loader';
import RouteWrapper from '../components/RouteWrapper';

const Dot = styled.div`
  margin: 5px 10px;
  border-radius: 50%;
  height: 5px;
  width: 5px;
  background: #697386;
`;

const StyledLayout = styled(Layout)`
  background: linear-gradient(8deg, rgb(249, 249, 249) 50%, #fff 50%);
`;

const PublicRoutesLayout = () => (
  <StyledLayout style={{ height: '100vh' }}>
    <Content>
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        wrap="nowrap"
        style={{ height: '100%', overflow: 'auto' }}
      >
        <Link to="/">
          <Logo
            alt="logo"
            src="/assets/logo.svg"
            style={{
              height: 50,
              margin: '30px 0px',
            }}
          />
        </Link>

        <React.Suspense fallback={<Loader />}>
          <RouteWrapper />
          <Outlet />
        </React.Suspense>
      </Flex>
    </Content>
    <Footer
      style={{
        background: 'transparent',
      }}
    >
      <Flex justifyContent="center" alignItems="center">
        <span>
          &copy; The Contentment Foundation'{new Date().getFullYear()}
        </span>
        <Dot />
        <a
          href="https://contentment.org/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>{' '}
        <Dot />
        <a
          href="https://contentment.org/tos"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms and Conditions
        </a>
        <Dot />
        <a
          href="https://www.contentment.org/cookie"
          target="_blank"
          rel="noopener noreferrer"
        >
          Cookie Policy
        </a>
      </Flex>
    </Footer>
  </StyledLayout>
);

export default PublicRoutesLayout;
