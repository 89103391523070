import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';
import Flex from './Flex';

const Wrapper = styled(Flex)`
  height: ${(props: any) => props.height || 'auto'};
  width: ${(props: any) => props.width || 'auto'};

  .ant-spin-dot i:nth-child(1) {
    background-color: #848ac4 !important;
  }
  .ant-spin-dot i:nth-child(2) {
    background-color: #0090be !important;
  }
  .ant-spin-dot i:nth-child(3) {
    background-color: #56bf97 !important;
  }
  .ant-spin-dot i:nth-child(4) {
    background-color: #f3b836 !important;
  }
`;

const Loader: React.FC<{
  height?: string;
  width?: string;
  size?: 'small' | 'default' | 'large' | undefined;
}> = ({ height, width, size }) => {
  return (
    <Wrapper
      justifyContent="center"
      alignItems="center"
      height={height}
      width={width}
    >
      <Spin size={size} />
    </Wrapper>
  );
};

Loader.defaultProps = {
  size: 'default',
};

export default Loader;
