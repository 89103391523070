import React, { useEffect, useMemo } from 'react';
import { Provider } from 'urql';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Routes,
  useNavigate,
} from 'react-router-dom';

import { openRoutesGqlClient, authRoutesGqlClient } from './config/graphql';
import AuthContext from './contexts/AuthContext';
import { SideBarContextProvider } from './contexts/SideBarContext';
import PublicRoutesLayout from './layouts/PublicRoutesLayout';
import Loader from './components/Common/Loader';
import { publicRoutes, adminRoutes, teacherRoutes } from './utils/routes';
import { Roles } from './constants/roles';
import { UserContextProvider } from './contexts/UserContext';
import RouteWrapper from './components/RouteWrapper';
import { CohortContextProvider } from './contexts/CohortContext';
import PrivateRoutesLayout from './layouts/PrivateRoutesLayout';

const RouteProvider = () => {
  const { auth } = React.useContext(AuthContext);

  if (!auth.id || !auth.token) {
    const router = createBrowserRouter([
      {
        element: <PublicRoutesLayout />,
        children: publicRoutes.map((routeObj) => ({
          path: routeObj.path,
          element: routeObj.component,
        })),
      },
    ]);
    return (
      <Provider value={openRoutesGqlClient}>
        <RouterProvider
          router={router}
          fallbackElement={<Loader height="100vh" />}
        />
      </Provider>
    );
  }

  const routes =
    auth.id && auth.token && auth.roles.includes(Roles.ORG_ADMIN)
      ? [...adminRoutes, ...teacherRoutes]
      : teacherRoutes;

  const router = createBrowserRouter([
    {
      element: <PrivateRoutesLayout />,
      children: routes.map((routeObj) => ({
        path: routeObj.path,
        element: routeObj.component,
      })),
    },
  ]);

  return (
    <Provider value={authRoutesGqlClient(auth)}>
      <UserContextProvider>
        <CohortContextProvider>
          <SideBarContextProvider>
            <RouterProvider
              router={router}
              fallbackElement={<Loader height="100vh" />}
            />
          </SideBarContextProvider>
        </CohortContextProvider>
      </UserContextProvider>
    </Provider>
  );
};

export default RouteProvider;
