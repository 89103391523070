import styled, { css } from 'styled-components';
import { media } from '../../utils/mediaQueries';

type Props = {
  key?: number | string;
  wrap?: string;
  alignItems?: string;
  flexDirection?: string;
  isResponsive?: boolean;
  justifyContent?: string;
  height?: string;
  width?: string;
};

const Flex = styled.div`
  ${({ key }) =>
    key &&
    css`
      key: ${key};
    `};
  display: flex;
  flex-direction: ${({ flexDirection, isResponsive }: Props) =>
    isResponsive && flexDirection !== 'column'
      ? 'column'
      : flexDirection || 'row'};
  flex-wrap: ${({ wrap }: Props) => wrap || 'wrap'};
  ${({ alignItems }) =>
    alignItems &&
    css`
      align-items: ${alignItems};
    `};
  ${({ justifyContent }: Props) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `};
  ${media.lg`
    flex-direction: ${({ flexDirection }: Props) => flexDirection || 'row'}
  `}
`;

export default Flex;
