import React from 'react';
import { Dropdown, Menu, Avatar } from 'antd';
import {
  CaretDownFilled,
  IdcardOutlined,
  BulbOutlined,
  LogoutOutlined,
  UserOutlined,
  TrophyOutlined,
} from '@ant-design/icons';

import { navigateToExternalUrl } from '../utils/navigateToExternalUrl';
import UserContext from '../contexts/UserContext';
import { AvatarMenuContainer, NameContainer } from './NavBarLayout';
import Flex from '../components/Common/Flex';
import { Link } from 'react-router-dom';

interface IProps {
  onLogout: () => Promise<void>;
}
const UserMenu: React.FC<IProps> = ({ onLogout }) => {
  const { user } = React.useContext(UserContext);

  const menu = (
    <Menu role="list">
      <Menu.Item key="0">
        <Link to="/profile">
          <span>
            <IdcardOutlined />
            &nbsp;&nbsp;Profile
          </span>
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="1"
        onClick={() =>
          navigateToExternalUrl('https://help.contentment.org', true)
        }
      >
        <span
          style={{
            color: '#2fc0ce',
          }}
        >
          <BulbOutlined type="bulb" />
          &nbsp;&nbsp;Help Center
        </span>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2">
        <Link to="/certificates">
          <span>
            <TrophyOutlined />
            &nbsp;&nbsp;My Certifications
          </span>
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3" onClick={onLogout}>
        <span>
          <LogoutOutlined />
          &nbsp;&nbsp;Sign Out
        </span>
      </Menu.Item>
    </Menu>
  );

  return (
    <AvatarMenuContainer>
      <Dropdown overlay={menu} trigger={['hover']}>
        <Flex alignItems="center" wrap="nowrap">
          <Avatar
            icon={<UserOutlined />}
            src={user.picture ? user.picture : '/assets/default-avatar.svg'}
          />
          <NameContainer>
            {user.firstName}&nbsp;{user.lastName}
          </NameContainer>
          <CaretDownFilled style={{ color: 'rgb(129, 141, 150)' }} />
        </Flex>
      </Dropdown>
    </AvatarMenuContainer>
  );
};

export default UserMenu;
