import React, { ReactNode, useEffect } from 'react';
import { useClient } from 'urql';

import Loader from '../components/Common/Loader';
import { GET_PROFILE_DETAILS } from '../graphql/user';
import AuthContext from './AuthContext';

interface UserContextInterface {
  user?: any;
  setUser: (arg0?: any) => void;
  refetchProfile: () => void;
}

interface UserContextProviderProps {
  children: ReactNode;
}

const UserContext = React.createContext<UserContextInterface>({
  user: null,
  setUser: () => {},
  refetchProfile: () => {},
});

export const UserContextProvider: React.FC<UserContextProviderProps> = ({
  children,
}) => {
  const [user, setUser] = React.useState({});
  const { auth } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(false);
  const client = useClient();

  const getUserData = async () => {
    if (!auth.id) return;
    try {
      setLoading(true);
      const response = await client
        .query(GET_PROFILE_DETAILS, { id: auth.id })
        .toPromise();
      const userData = response.data.auth_users[0];
      window.analytics.identify(userData.id, {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        gender: userData.gender,
        organization: userData.organization.name,
        nationality: userData.nationality,
      });
      let curriculum = userData?.member?.curriculum
        ? userData.member.curriculum
        : new Array(4).fill(false);
      if (curriculum?.length < 4) {
        curriculum = [
          ...curriculum,
          ...new Array(4 - curriculum.length).fill(false),
        ];
      }
      const data = {
        ...userData,
        showClosedWelcomeModal: userData.member.welcomeSurvey === false,
        curriculum,
        isOrganizationCohortModeEnabled: Boolean(
          userData?.organization.cohortModeEnabledAt,
        ),
        isOrganizationCohortHistoryAvailable: Boolean(
          userData?.organization.cohort_histories_aggregate.aggregate.count,
        ),
        assignedOrganizationCohortId: userData?.organization.cohortId,
        isCohortTrainingMember: Boolean(
          userData?.cohort_training_members?.length,
        ),
      };
      setUser(data);
      return response.data.auth_users[0];
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserData();
  }, [auth.id]);

  if (loading || !user || !Object.keys(user).length) {
    return <Loader height="100vh" size="large" />;
  }

  return (
    <UserContext.Provider
      value={{ user, setUser, refetchProfile: getUserData }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
