export const env: Record<string, unknown> = {
  recordEvents: process.env.RECORD_EVENTS !== 'false',
  stripeKey:
    process.env.VERCEL_GIT_COMMIT_REF !== 'master'
      ? process.env.STRIPE_TEST_KEY
      : process.env.STRIPE_KEY,
  apiURL:
    process.env.VERCEL_GIT_COMMIT_REF !== 'master'
      ? process.env.API_URL_DEV
      : process.env.API_URL,
  graphqlURL:
    process.env.VERCEL_GIT_COMMIT_REF !== 'master'
      ? process.env.GRAPHQL_URL_DEV
      : process.env.GRAPHQL_URL,
  eventGraphqlURL:
    process.env.VERCEL_GIT_COMMIT_REF !== 'master'
      ? process.env.EVENT_API_DEV
      : process.env.EVENT_API,
  subscriptionsURL:
    process.env.VERCEL_GIT_COMMIT_REF !== 'master'
      ? process.env.SUBSCRIPTIONS_URL_DEV
      : process.env.SUBSCRIPTIONS_URL,
};
