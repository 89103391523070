import { gql } from 'urql';

export const GET_COHORTS = gql`
  query GetCohorts($limit: Float!, $offset: Float!) {
    get_cohorts(limit: $limit, offset: $offset) {
      success
      totalData
      data {
        id
        name
        startTime
        endTime
        timeZone
        status
        maxOrganizations
        organizations {
          id
          name
        }
        cohort_trainings {
          id
          status
          startTime
          endTime
          cohort_training_type {
            id
            type
            description
          }
          masterTrainerUser {
            firstName
            lastName
            email
          }
          assistantTrainerUser {
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;

export const JOIN_COHORT = gql`
  mutation joinCohort($cohortId: String!) {
    join_cohort(cohortId: $cohortId) {
      success
      message
    }
  }
`;

export const LEAVE_COHORT = gql`
  mutation leaveCohort {
    leave_cohort {
      success
      message
    }
  }
`;

export const SAVE_COHORT_TRAINING_FEEDBACK = gql`
  mutation saveCohortTrainingFeedback(
    $trainingMemberId: uuid!
    $surveyCompletedAt: timestamptz!
    $surveyResult: jsonb!
    $version: Int!
  ) {
    update_schools_cohort_training_members(
      where: { id: { _eq: $trainingMemberId } }
      _set: {
        feedbackSurveyCompletedAt: $surveyCompletedAt
        feedbackSurveyResult: $surveyResult
        version: $version
      }
    ) {
      affected_rows
    }
  }
`;

export const COHORT_TRAINING_STATUS_UPDATED = gql`
  subscription CohortTrainingStatusUpdated($cohortId: uuid!) {
    schools_cohorts(where: { id: { _eq: $cohortId } }) {
      cohort_trainings {
        id
        status
      }
    }
  }
`;

export const GET_COHORT_TRAINING_MEMBERS = gql`
  query getCohortTrainingMembers(
    $where: schools_cohort_training_members_bool_exp
  ) {
    schools_cohort_training_members(
      order_by: {
        cohort_training: {
          cohort: { startTime: asc }
          cohort_training_type: { order: asc }
        }
      }
      where: $where
    ) {
      id
      cohortTrainingId
      attendedAt
      feedbackSurveyCompletedAt
      userId
      user {
        email
      }
      cohort_training {
        id
        startTime
        endTime
        status
        cohortId
        cohort {
          name
          timeZone
        }
        cohort_training_type {
          id
          type
          description
        }
      }
    }
  }
`;

export const REMOVE_TRAINING_MEMBERS = gql`
  mutation removeTrainingMembers($userIds: [String!]!) {
    remove_training_members(userIds: $userIds) {
      success
    }
  }
`;

export const ADD_TRAINING_MEMBERS = gql`
  mutation addTrainingMembers($userIds: [String!]!) {
    add_training_members(userIds: $userIds) {
      success
    }
  }
`;
