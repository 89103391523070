import React from 'react';
import { gql } from '@urql/core';

import AuthContext from './AuthContext';
import { eventGQL } from '../config/event';
import { env } from '../config/env';

interface EventInput {
  eventType: string;
  eventData?: {
    eventClass?: string;
    resourceID?: string;
    resourceType?: string;
    message?: string;
    platform?: string;
  };
}

interface EventContextInterface {
  registerEvent: (input: EventInput) => void;
}

export const EventContext = React.createContext<EventContextInterface>({
  registerEvent: () => {},
});

export const EventContextProvider = ({ children }: any) => {
  const { auth } = React.useContext(AuthContext);

  const registerEvent = (input: EventInput) => {
    if (env.recordEvents) {
      const headers: Record<string, string> = {};
      if (auth?.token) {
        headers['Authorization'] = `Bearer ${auth.token}`;
      }

      const uid = auth?.id || sessionStorage.getItem('cfc_session');
      if (!uid) {
        sessionStorage.setItem(
          'cfc_session',
          `session_${Date.now().toString()}`,
        );
      }

      eventGQL(headers)
        .mutation(
          gql`
            mutation registerKafkaEvent($data: RegisterKafkaEventInput!) {
              register_kafka_event(event: $data) {
                id
                eventType
                context {
                  ip
                  locale
                }
              }
            }
          `,
          {
            data: {
              eventType: input.eventType,
              eventClass: input.eventData?.eventClass || 'fact',
              userId: uid,
              path: window.location.pathname,
              url: window.location.toString(),
              locale: window.navigator.language,
              pageTitle: document.title,
              platform: `SCHOOL_PLATFORM`,
              ...(input.eventData || {}),
            },
          },
        )
        .toPromise();
    }
  };

  return (
    <EventContext.Provider value={{ registerEvent }}>
      {children}
    </EventContext.Provider>
  );
};

const useEvent = () => React.useContext(EventContext);

export default useEvent;
