import { env } from '../config/env';

interface TokenData {
  id: string;
  token: string;
  roles: string[];
}

const defaultToken = { id: '', token: '', roles: ['user'] };
let tokenData: TokenData = defaultToken;

// expires 15second earlier then actual timeout
export const expiresIn = 3600 * 45 * 1000;

// export const expiresIn = 50 * 1000;
export const setRefreshToken = (token: TokenData): void => {
  tokenData = token;
};

export const getRefreshToken = async (
  shouldGetNewToken = false,
): Promise<TokenData> => {
  try {
    if (shouldGetNewToken || !tokenData.token || !tokenData.id) {
      const res = await fetch(`${env.apiURL}/v1/auth/token`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'x-platform': 'SCHOOL_PLATFORM',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      });

      if (res.status > 300 && !shouldGetNewToken) {
        // const error = new Error(res.statusText);
        return defaultToken;
      }

      const data = await res.json();
      if (data) {
        tokenData = data;
      } else {
        return defaultToken;
      }
    }
    return tokenData;
  } catch (err) {
    return defaultToken;
  }
};
