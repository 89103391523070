import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import './index.less';
import App from './App';

Sentry.init({
  dsn: process.env.SENTRY_DSN || '',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  maxBreadcrumbs: 50,
});

ReactDOM.render(<App />, document.getElementById('root'));
