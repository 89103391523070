// Todo: delete not used anymore
import { createClient } from '@urql/core';
import { env } from './env';

export const eventGQL = (headers?: Record<string, string>) =>
  createClient({
    url: `${env.eventGraphqlURL}`,
    fetchOptions: () => {
      return {
        headers: headers,
        credentials: 'include',
      };
    },
  });
