import { gql } from 'urql';

export const LOGIN = gql`
  mutation login($email: String!, $password: String!, $otp: String) {
    login(email: $email, password: $password, otp: $otp) {
      id
      token
      isMultiFactorAuthEnabled
      isDeleted
      organization {
        id
        isActive
      }
      user_roles {
        role {
          name
        }
      }
    }
  }
`;

export const CHANGE_EMAIL = gql`
  mutation changeEmail($id: uuid!, $email: String!) {
    update_auth_users(where: { id: { _eq: $id } }, _set: { email: $email }) {
      affected_rows
    }
  }
`;

export const CHANGE_NAME = gql`
  mutation changeEmail($id: uuid!, $firstName: String!, $lastName: String) {
    update_auth_users(
      where: { id: { _eq: $id } }
      _set: { firstName: $firstName, lastName: $lastName }
    ) {
      affected_rows
    }
  }
`;

export const VERIFY_CERTIFICATE_NAME = gql`
  mutation updateCertificateNameVerifiedAt(
    $id: uuid!
    $firstName: String!
    $lastName: String!
    $certificateNameVerifiedAt: timestamptz!
  ) {
    update_auth_users(
      where: { id: { _eq: $id } }
      _set: {
        firstName: $firstName
        lastName: $lastName
        certificateNameVerifiedAt: $certificateNameVerifiedAt
      }
    ) {
      affected_rows
    }
  }
`;

export const IS_UNIQUE_EMAIL = gql`
  query isUniqueEmail($email: String!) {
    is_unique_email(email: $email) {
      unique
      user {
        id
        email
        isVerified
        user_roles {
          role {
            name
          }
        }
        member {
          id
        }
        organization {
          id
          isActive
        }
      }
    }
  }
`;

export const SEND_OTP = gql`
  mutation sendOTP($email: String!) {
    send_otp(email: $email) {
      success
      message
    }
  }
`;

export const VERIFY_OTP = gql`
  mutation verifyOTP($email: String!, $otp: String!) {
    verify_otp(email: $email, otp: $otp) {
      success
      message
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!, $otp: String!, $password: String!) {
    forgot_password(email: $email, password: $password, otp: $otp) {
      success
      message
    }
  }
`;

export const MEMBER_SIGN_UP = gql`
  mutation signUpMember(
    $email: String!
    $otp: String
    $password: String!
    $isInvitedMember: Boolean
  ) {
    sign_up_member(
      email: $email
      password: $password
      otp: $otp
      isInvitedMember: $isInvitedMember
    ) {
      success
      token
      id
      user_roles {
        role {
          name
        }
      }
    }
  }
`;

export const LOGOUT = gql`
  mutation logout($userId: String!) {
    logout(userId: $userId) {
      success
    }
  }
`;

export const GET_PROFILE_DETAILS = gql`
  query getUserDetails($id: uuid!) {
    auth_users(where: { id: { _eq: $id } }) {
      id
      firstName
      gender
      isDeleted
      isMultiFactorAuthEnabled
      lastName
      dob
      email
      phone
      marital
      nationality
      colorBlindness
      race
      picture
      countryOfResidence
      yearOfBirth
      relationshipStatus
      employmentStatus
      cohort_training_members {
        attendedAt
        feedbackSurveyCompletedAt
      }
      user_roles {
        role {
          name
        }
      }
      organization {
        id
        name
        plan: schoolPlatformSubscription(path: "plan")
        country
        state
        city
        addressLine1
        addressLine2
        pincode
        address
        type
        schoolPlatformSubscription
        familyAppSubscription
        isActive
        automatedSurveyConfig
        createdAt
        maxTrainingMembers
        cohortModeEnabledAt
        cohortId
        cohort {
          id
          name
          startTime
          endTime
          timeZone
          cohort_trainings {
            id
            startTime
            endTime
            status
            cohortId
            cohort {
              name
              timeZone
            }
            cohort_training_type {
              id
              type
              description
            }
          }
        }
        cohort_histories_aggregate {
          aggregate {
            count
          }
        }
        organization_subscriptions {
          createdAt
          subId
          subType
          customer
          amount
          coupon
          start
          end
          status
          plan
          quantity
        }
      }
      member {
        classSize
        yearsAdmin
        numberOfStudents
        yearsOfTeaching
        currentSchoolYears
        subjects
        salary
        education
        grades
        welcomeSurvey
        introVideo
        role
        curriculum
        yearsOfWorking
        responsibility
        roles
      }
      nationality
      isDemo
      certificateNameVerifiedAt
      achievements {
        id
        type
        title
        issuedAt
      }
    }
  }
`;

export const getUpdateAdminProfileQuery = (data: any) => {
  let updateAdminProfileQueryProps = `
    $id: uuid!
    $firstName: String!
    $lastName: String!
    $colorBlindness: Boolean
    $gender: String!
    $nationality: String!
    $organizationId: uuid!
    $address: jsonb
    $numberOfStudents: Int
    $education: String
    $country: String
    $state: String
    $city: String
    $addressLine1: String
    $addressLine2: String
    $pincode: String
    $countryOfResidence: String
    $yearOfBirth: String
    $relationshipStatus: String
    $employmentStatus: String
    $yearsOfWorking: String
    $responsibility: String
    $roles: jsonb
    $subjects: jsonb
    $grades: jsonb
    `;

  let userQuery = `
    firstName: $firstName
    lastName: $lastName
    colorBlindness: $colorBlindness
    gender: $gender
    nationality: $nationality
    countryOfResidence: $countryOfResidence
    yearOfBirth: $yearOfBirth
    relationshipStatus: $relationshipStatus
    employmentStatus: $employmentStatus
  `;

  let memberQuery = `
    welcomeSurvey: true
    numberOfStudents: $numberOfStudents
    education: $education
    yearsOfWorking: $yearsOfWorking
    responsibility: $responsibility
    roles: $roles
    subjects: $subjects
    grades: $grades
    `;

  let orgQuery = `
    address: $address
    country: $country
    state: $state
    city: $city
    addressLine1: $addressLine1
    addressLine2: $addressLine2
    pincode: $pincode
  `;

  const updateAdminProfileQuery = gql`
    mutation updateProfile(
      ${updateAdminProfileQueryProps}
    ) {
      update_auth_users(
        where: { id: { _eq: $id } }
        _set: {
          ${userQuery}
        }
      ) {
        affected_rows
      }
      update_auth_organization(
        where: { id: { _eq: $organizationId } }
        _set: {
          ${orgQuery}
        }
      ) {
        affected_rows
      }
      update_schools_members(
        where: { userId: { _eq: $id } }
        _set: {
          ${memberQuery}
        }
      ) {
        affected_rows
      }
    }
  `;

  return updateAdminProfileQuery;
};

export const getUpdateTeacherProfileQuery = (data: any) => {
  let updateTeacherProfileQueryProps = `
    $id: uuid!
    $firstName: String!
    $lastName: String!
    $colorBlindness: Boolean
    $gender: String!
    $nationality: String!
    $numberOfStudents: Int
    $education: String
    $countryOfResidence: String
    $yearOfBirth: String
    $relationshipStatus: String
    $employmentStatus: String
    $yearsOfWorking: String
    $responsibility: String
    $roles: jsonb
    $subjects: jsonb
    $grades: jsonb
    `;

  let userQuery = `
    firstName: $firstName
    lastName: $lastName
    colorBlindness: $colorBlindness
    gender: $gender
    nationality: $nationality
    countryOfResidence: $countryOfResidence
    yearOfBirth: $yearOfBirth
    relationshipStatus: $relationshipStatus
    employmentStatus: $employmentStatus
  `;

  let memberQuery = `
    welcomeSurvey: true
    numberOfStudents: $numberOfStudents
    education: $education
    yearsOfWorking: $yearsOfWorking
    responsibility: $responsibility
    roles: $roles
    subjects: $subjects
    grades: $grades
    `;

  const updateTeacherProfile = gql`
    mutation updateProfile(
      ${updateTeacherProfileQueryProps}
    ) {
      update_auth_users(
        where: { id: { _eq: $id } }
        _set: {
          ${userQuery}
        }
      ) {
        affected_rows
      }
      update_schools_members(
        where: { userId: { _eq: $id } }
        _set: {
          ${memberQuery}
        }
      ) {
        affected_rows
      }
    }
  `;

  return updateTeacherProfile;
};

export const getUpdatePersonalDetailsQuery = (data: any) => {
  let updateTeacherProfileQueryProps = `
    $id: uuid!
    $firstName: String!
    $lastName: String!
    $colorBlindness: Boolean
    $gender: String!
    $nationality: String!
    $education: String
    $countryOfResidence: String
    $yearOfBirth: String
    $relationshipStatus: String
    $employmentStatus: String
    $yearsOfWorking: String
    `;

  let userQuery = `
    firstName: $firstName
    lastName: $lastName
    colorBlindness: $colorBlindness
    gender: $gender
    nationality: $nationality
    countryOfResidence: $countryOfResidence
    yearOfBirth: $yearOfBirth
    relationshipStatus: $relationshipStatus
    employmentStatus: $employmentStatus
  `;

  let memberQuery = `
    welcomeSurvey: true
    education: $education
    yearsOfWorking: $yearsOfWorking
    `;

  const updateTeacherProfile = gql`
    mutation updateProfile(
      ${updateTeacherProfileQueryProps}
    ) {
      update_auth_users(
        where: { id: { _eq: $id } }
        _set: {
          ${userQuery}
        }
      ) {
        affected_rows
      }
      update_schools_members(
        where: { userId: { _eq: $id } }
        _set: {
          ${memberQuery}
        }
      ) {
        affected_rows
      }
    }
  `;

  return updateTeacherProfile;
};

export const CHANGE_PASSWORD = gql`
  mutation changePassword($currentPassword: String!, $newPassword: String!) {
    change_password(
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      success
    }
  }
`;

export const CHANGE_PROFILE_EMAIL = gql`
  mutation changeEmail($oldEmail: String!, $newEmail: String!) {
    update_auth_users(
      where: { email: { _eq: $oldEmail } }
      _set: { email: $newEmail }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_PROFILE_PICTURE = gql`
  mutation updateProfile($picture: String!, $id: uuid!) {
    update_auth_users(
      _set: { picture: $picture }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

export const GET_ADMIN_COUNT = gql`
  query getAdminCount($id: uuid!) {
    auth_users_aggregate(
      where: { user_roles: { roleId: { _eq: $id } }, isDeleted: { _eq: false } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const UPDATE_MULTI_FACTOR_AUTH = gql`
  mutation updateMiltiFactorAuth(
    $id: uuid!
    $isMultiFactorAuthEnabled: Boolean!
  ) {
    update_auth_users(
      where: { id: { _eq: $id } }
      _set: { isMultiFactorAuthEnabled: $isMultiFactorAuthEnabled }
    ) {
      returning {
        isMultiFactorAuthEnabled
      }
    }
  }
`;

export const GET_ACTIVE_USERS_FOR_SURVEY = gql`
  query getUsersForSurvey(
    $limit: Int!
    $offset: Int!
    $where: auth_users_bool_exp!
    $order_by: [auth_users_order_by!]
  ) {
    auth_users_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    auth_users(
      where: $where
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      id
      email
      firstName
      lastName
    }
  }
`;
