export enum CurriculumTabs {
  CURRICULUM = 'Curriculum',
  WELLBEING_LIBRARY = 'Wellbeing Library',
  M3_PRE_SCHOOL = 'M3 Pre-School',
  M3_PRIMARY = 'M3 Primary',
}

export enum AgeGroups {
  INFANTS = 'Infants',
  AGE2 = '2 years old',
  PRESCHOOL = 'Preschool',
  AGE_K_2 = 'K-2 (5-7 years old)',
  AGE_3_5 = '3-5 (8-11 years old)',
  AGE_6_8 = '6-8 (12-14 years old)',
  HS = 'High School',
}

export enum CurriculumIndex {
  mindfulness = 0,
  community = 1,
  selfCuriosity = 2,
  contentment = 3,
}
